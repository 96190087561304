<template>
	<main class="container-wide">
		<div class="flex space-between mb-40">
			<div>
				<h1 class="mb-10">Kontu uppgerðir</h1>

				<p class="color-grey">Yvirlit yvir allar kontu uppgerðir</p>
			</div>

			<div class="btn-add cursor-pointer" @click="overlay.visible = true" title="Legg 'Account Settlement' upp"></div>
		</div>

		<div class="flex justify-center mt-80" v-if="loading">
			<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
		</div>

		<div v-if="!loading" class="table-container">
			<table>
				<thead>
					<th>Dato</th>

					<th>Kundi</th>

					<th class="text-right">Søla</th>

					<th class="text-right">Kortkost</th>

					<th class="text-right">SWB/FAK</th>

					<th class="text-right">Saldo</th>

					<th>&nbsp;</th>
				</thead>

				<tbody>
					<tr v-for="(settlement, index) in settlements" :key="settlement.id">
						<td>{{ new Date(settlement.settledAt).format('%d-%m-%y %H:%M') }}</td>

						<td>{{ settlement.description }}</td>

						<td class="text-right">
							<div v-if="settlement.cardCost">
								{{ settlement.amount ? Number(settlement.amount).format(2) : '-' }}
							</div>

							<div v-if="!settlement.cardCost">-</div>
						</td>

						<td class="text-right">
							<div v-if="settlement.cardCost">({{ settlement.cardCost ? Number(settlement.cardCost).format(2) : '-' }})</div>

							<div v-if="!settlement.cardCost">-</div>
						</td>

						<td class="text-right">
							<div v-if="settlement.cardCost">-</div>

							<div v-if="!settlement.cardCost">
								<div v-if="settlement.amount >= 0">
									{{ settlement.amount ? Number(settlement.amount).format(2) : '-' }}
								</div>

								<div v-if="settlement.amount < 0">({{ settlement.amount ? Math.abs(Number(settlement.amount)).format(2) : '-' }})</div>
							</div>
						</td>

						<td class="text-right">{{ Number(balances[index]).format(2) }}</td>

						<td class="flex justify-end right whitespace-nowrap">
							<template v-if="!settlement.cardCost">
								<i
									class="far fa-trash-alt cursor-pointer hover:color-red mr-15"
									title="Strika uppgerð"
									@click="deleteSettlement(settlement.id)"
								></i>

								<i class="far fa-file-pdf cursor-pointer hover:color-blue opacity-10 pointer-events-none" @click="downloadFile(settlement)"></i>
							</template>

							<template v-if="settlement.cardCost">
								<i
									class="far fa-trash-alt cursor-pointer hover:color-red opacity-10 pointer-events-none mr-15"
									@click="deleteSettlement(settlement.id)"
								></i>

								<i class="far fa-file-pdf cursor-pointer hover:color-blue" title="Tak fílu niður" @click="downloadFile(settlement)"></i>
							</template>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<AddAccountSettlementOverlay :visible="overlay.visible" @add="addSettlement" @close="overlay.visible = false" />
	</main>
</template>

<script>
import axios from 'axios';

import AddAccountSettlementOverlay from '../components/AddAccountSettlementOverlay.vue';

export default {
	name: 'AccountSettlements',

	components: {
		AddAccountSettlementOverlay,
	},

	data() {
		return {
			loading: true,

			settlements: null,
			balances: [],

			overlay: {
				visible: false,
			},
		};
	},

	watch: {
		settlements: function (settlements) {
			this.balances = [];

			let total = 0;

			const reversedSettlements = settlements.slice().reverse();

			for (const settlement of reversedSettlements) {
				// Use card cost if it is present (cardCost comes from transaction).
				// If settlement is manually created by Terminal, no cardCost is present and we use the manually typed in amount
				total += settlement.cardCost ? Number(settlement.cardCost) : Number(settlement.amount);

				this.balances.push(total);
			}

			this.balances.reverse();
		},
	},

	async mounted() {
		await axios
			.get('/account-settlements')
			.then((response) => {
				this.settlements = response.data;
			})
			.catch(() => {})
			.finally(() => {
				this.loading = false;
			});
	},

	methods: {
		addSettlement() {
			this.$router.go();
		},

		async downloadFile(settlement) {
			const file = settlement.transactionSettlementFile?.file;

			if (!file) {
				return '';
			}

			await axios
				.get(`/files/${file.uuid}/url`)
				.then((response) => {
					window.location.href = response.data;
				})
				.catch(() => {});
		},

		async deleteSettlement(settlementId) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			this.settlements = this.settlements.filter((settlement) => settlement.id != settlementId);

			await axios
				.delete(`/account-settlements/${settlementId}`)
				.then((response) => {
					console.log(response);
				})
				.catch(() => {});
		},
	},
};
</script>
