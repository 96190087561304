<template>
	<VOverlay :visible="visible" @close="$emit('close')">
		<div class="flex space-between align-center mb-40">
			<div>
				<h1 class="mb-10">Nýggja kontu uppgerð</h1>

				<p class="color-grey">Legg eitt nýggja kontu uppgerð afturat.</p>
			</div>

			<div class="overlay-close" @click.prevent="$emit('close')"></div>
		</div>

		<div>
			<div class="grid grid-2 grid-gap-20 align-end mb-30">
				<v-date-picker v-model="form.settledAt" mode="dateTime" is24hr :popover="{ visibility: 'focus' }">
					<template #default="{ inputValue, inputEvents }">
						<div>
							<label class="block mb-5" for="from">Uppgjørt tann</label>

							<div class="date-input">
								<input type="text" :value="inputValue" v-on="inputEvents" />
							</div>
						</div>
					</template>
				</v-date-picker>

				<InputGroup v-model="form.amount" label="Krónur" sublabel="Minst til minus (-)" placeholder="-9999.99" class="mb-0" />
			</div>

			<InputGroup v-model="form.description" label="SWB/FAK" sublabel="Lýsing av uppgerð" />
		</div>

		<div class="flex justify-end">
			<div class="btn inline-block" @click="save">Stovna</div>
		</div>
	</VOverlay>
</template>

<script>
import axios from 'axios';

import VOverlay from '@/components/VOverlay.vue';
import InputGroup from '@/components/InputGroup.vue';

export default {
	name: 'AddBankOverlay',

	props: {
		visible: Boolean,
		customerId: Number,
		throughAgreementLink: Boolean,
	},

	components: {
		VOverlay,
		InputGroup,
	},

	data() {
		return {
			form: {
				settledAt: new Date(),
				description: '',
				amount: '',
			},
		};
	},

	methods: {
		async save() {
			if (!this.form.settledAt) {
				this.form.settledAt = new Date();
			}

			await axios
				.post('/account-settlements', {
					settledAt: this.form.settledAt,
					description: this.form.description,
					amount: this.form.amount,
				})
				.then((response) => {
					this.settlements = response.data;

					this.$emit('add', this.settlements);
				})
				.catch(() => {});
		},
	},
};
</script>
